<template>
  <md-app>
    <md-app-content>
      <div class="main" v-show="isAuth === true">
        <div class="wrapper" v-if="!loading">
          <div class="sidebar" :class="{'hidden': currentChatId > 0}">
            <div class="sidebar-header">
              <div style="display: flex; align-items: center; justify-content: center">
                <img style="margin: 16px 0 8px; width: 10em;"
                     src="https://www.enchy.ru/upload/iblock/ef2/ef25a2ef179a75a4328d3a64c40328e7.png" alt="Логотип"/>
              </div>
              <div class="label" style="color: #fff; position: relative"> Учетные записи</div>

              <div class="chat-preview profile-freview md-card"
                   v-bind:class="{'chat-preview-active': currentProfile && currentProfile.id === it.id}"
                   v-bind:style="{'opacity': it.hasChats ? 1 : 0.75 }"
                   v-for="it in profiles" :key="it.id" @click="selectProfile(it)">
                <div style="width: 100%"> {{ it.name }} </div> <svg v-if="it.hasChats" style="width: 24px; height: 24px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>message-plus-outline</title><path fill="tomato" d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M20 16H5.2L4 17.2V4H20V16M16 9V11H13V14H11V11H8V9H11V6H13V9H16Z" /></svg>
              </div>
              <div class="chat-preview md-card"
                   style="box-shadow: none; display: flex;position: relative; align-items: center; justify-content: center; text-transform: uppercase; text-align: center; background: #faaea226; color: #fff; cursor: pointer"
                   @click="exit()">
                <svg style="width: 16px; height: 16px; margin-bottom: 2px; margin-right: 8px;"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>logout</title>
                  <path fill="#fff"
                        d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z"/>
                </svg>
                Выйти из чата
              </div>
            </div>
            <span class="label"> Чаты </span>
            <div class="sidebar-search" v-if="chatsList.length > 5">
              <md-field>
                <label>Поиск по ФИО</label>
                <md-input v-model="search"></md-input>
              </md-field>
            </div>
            <div class="sidebar-content">
              <div style="text-align: center; margin-top: 56px" v-if="chatsList.length === 0">
                Нет активных чатов
              </div>
              <div style="display: flex" class="chat-preview md-card" v-for="it in chatsList" v-bind:key="it.id"
                   v-on:click="selectChat(it)"
                   v-bind:class="{'chat-preview-active': chat && chat.id === it.id, 'chat-preview-closed': isClosed(it)}">
                <div style="width: 100%"><div style="display: flex">
                  <div class="chat-preview-clients"> {{ it.users.filter(e => e.id !== currentProfile.id).map(e => e.name).join(", ") }}</div>
                  <div class="chat-preview-date"> {{ getMessageDate(it.messages.length ? it.messages[0].created : it.created) }}</div>
                </div>
                <div class="chat-preview-content" v-if="it.messages.length">
                  <div class="chat-preview-text"> {{ it.messages[0].content }}</div>
                </div>
                </div>
                <img style="width:56px;height:56px; margin-left: 12px;" :src="`${require('@/assets/img_1.png')}`" v-if="isClosed(it)" />
              </div>
            </div>
            <div class="offline" v-if="isOffline"> Подключение...</div>
          </div>
          <div class="content" v-if="currentChatId > -1" :class="{'hidden': currentChatId < 0}">
            <div class="content-header" :class="{'hidden': currentChatId < 1}" @click="clearChat()">
              <button class="back">
                <svg style="width: 24px; height: 24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>
                  arrow-left</title>
                  <path fill="#404344" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"/>
                </svg>
              </button>
              <div class="chat-preview-clients" style="display: flex; flex-direction: column; padding-left: 6px;">
                <div style="display: flex; align-items: center">
                  <img class="photo" :src="`https://lk.sadkomed.ru/customer-assets/media/${photo(chat.users)}/small`" v-if="photo(chat.users)" />
                  <div>
                    {{ chat.users.filter(e => e.id !== currentProfile.id).map(e => e.name).join(", ") }}
                    <div style="font-size: 13px; font-weight: normal; color: #885f01"> Отвечаем в течении 24 часов</div>
                  </div>
                </div>
              </div>
              <div style="width: 56px; height: 56px; display: flex; align-items: center; justify-content: center">
                <transition name="fade">
                  <span class="loader" v-show="isOffline || isLoadingMessages"></span>
                </transition>
              </div>
            </div>
            <div class="messages" v-chat-scroll>
              <div style="display: flex; align-items: center; justify-content: center; height: 100%; color: #535353"
                   v-if="isLoadingMessages && messages.length === 0">
                Загрузка сообщений
              </div>
              <div v-for="it in getMessages" v-bind:key="it.id" v-else>
                <div class="message" :id="it.id" v-observe-visibility="(e, ee) => visibilityChanged(it, e, ee)" :class="it.user.id === -1 ? 'system-message' : (it.user.id !== currentProfile.id ? 'patient-message' : 'doctor-message')">
                  <div>
                    <div class="message-text">
                      <span v-if="it.content.length" v-html="getMessage(it.content, it.user.id === currentProfile.id)"></span>
                      <!--span v-if="it.content.length" v-html="it.seen.map(it => it.id)"></span-->
                    </div>
                    <div class="message-date" v-if="it.user.id !== -1">
                      <!--svg v-if="it.user.id !== currentProfile.id" style="width: 14px; height: 14px; margin-right: 6px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-all</title><path fill="deepskyblue" d="M0.41,13.41L6,19L7.41,17.58L1.83,12M22.24,5.58L11.66,16.17L7.5,12L6.07,13.41L11.66,19L23.66,7M18,7L16.59,5.58L10.24,11.93L11.66,13.34L18,7Z" /></svg-->
                      {{ getMessageDate(it.created) }}
                      <!--svg v-if="it.user.id === currentProfile.id" style="width: 14px; height: 14px; margin-left: 6px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-all</title><path fill="deepskyblue" d="M0.41,13.41L6,19L7.41,17.58L1.83,12M22.24,5.58L11.66,16.17L7.5,12L6.07,13.41L11.66,19L23.66,7M18,7L16.59,5.58L10.24,11.93L11.66,13.34L18,7Z" /></svg-->
                    </div>
                  </div>
                </div>
              </div>
              <div class="messages-bg" :style="{ 'background-image': `url(${require('@/assets/img.png')})`}"></div>
            </div>
            <div class="editor-wrapper">
            <div class="editor" id="form">
              <div v-if="!isClosed(byId(currentChatId))">
                <textarea v-model="message" placeholder="Введите текст сообщения..." @keyup.enter="send" :disabled="isClosed(byId(currentChatId))"></textarea>
                <hr>
                <i class="editor-hint"><small> Shift + Enter для отправки </small></i>
                <button id="send-btn" v-on:click="send" class="send-btn" :disabled="isOffline || isLoadingMessages || isClosed(byId(currentChatId))">
                  {{ isClosed(byId(currentChatId)) ? 'Чат закрыт' : 'Отправить' }}
                </button>
              </div>
              <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%" v-else>
                <div> Чат закрыт </div> <div style="color: #565656"> <i> {{ new Date(byId(currentChatId).deleted).toLocaleString() }} </i> </div>
              </div>
            </div>
            </div>
          </div>
          <div class="chat-hint" v-else> Выберите чат</div>
        </div>
        <div v-else>
          Загрузка
        </div>
      </div>
      <div class="login-wrapper"
           style="height: calc(100vh); display: flex; align-items: center; justify-content: center" v-show="!isAuth && patientId">
        <div class="login" v-if="!loading">
          <h2>Вход</h2>
          <p class="login-error"> {{ error }} </p>
          <md-field>
            <label>Введите номер телефона</label>
            <md-input type="phone" v-model="phone" @input="clearError" v-mask="'+7 9## ### ####'"
                      @keyup.enter="() => { clearedPhone.length === 11 && auth() }"></md-input>
          </md-field>
          <md-field v-if="code !== null">
            <label>Введите код из смс</label>
            <md-input id="code" v-model="code" @input="clearError" type="number" @keyup.enter="auth()"
                      autocomplete="off"></md-input>
          </md-field>
          <button @click="auth" :disabled="disabledLogin"> {{ authBtn }}</button>
        </div>


      </div>
      <div class="bg1" :style="{ 'background-image': `url(${require('@/assets/bg.png')})`}"/>

    </md-app-content>
  </md-app>
</template>

<script>
import axios from "axios";
import SockJS from 'sockjs-client'
import Stomp from 'stompjs';
import moment from 'moment'
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import {WebSocket} from 'ws';

Object.assign(global, {WebSocket});

export default {
  name: "Chats",
  data() {
    return {
      search: '',
      loading: false,
      loadingPhone: false,
      loadingCode: false,
      phone: '',
      code: null,
      error: '',
      chats: [],
      currentChatId: -1,
      message: '',
      messages: [],
      messagesSeen: [],
      profiles: [],
      currentProfile: null,
      messagesSubscription: null,
      stompClient: null,
      isOffline: true,
      isAuth: false,
      isLoadingMessages: false
    };
  },

  mounted() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--vh2', `${vh}px`)
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })

    this.checkToken()
        .then(res => this.isAuth = !!res)
        .then(() => this.connect())
  },

  watch: {
    currentProfile(value, oldValue) {
      this.currentChatId = -1

      const onMessageReceived = message => {
        const chat = JSON.parse(message.body)
        console.log('currentProfile onMessageReceived')
        console.log(chat)
        if (this.chats.some(it => it.id === chat.id)) {
          const chats = this.chats.filter(it => it.id !== chat.id)
          chats.push(chat)
          this.chats = chats
        } else {
          this.chats.push(chat)
        }
        const messages = this.messages
        messages.sort((b, a) => new Date(a.created).getTime() - new Date(b.created).getTime())
        this.loadMessages().then(chat => {
          if (chat.messages && messages[0].id !== chat.messages[0].id)
            this.playSound()
        })
      }
      if (this.stompClient !== null) {
        if (oldValue) {
          if (this.messagesSubscription)
            this.messagesSubscription.unsubscribe()
        }
        this.messagesSubscription = this.stompClient.subscribe(`/patient/${value.uid}`, onMessageReceived)
      }
      this.loadChats()
    }

  },

  computed: {

    getMessages() {
      const messages = this.messages
      messages.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
      return messages
    },

    patientId() {
      return window.location.pathname.replace('/', '')
    },

    clearedPhone() {
      return this.phone.split('').filter(it => '01234567890'.indexOf(it) > -1).join('')
    },

    disabledLogin() {
      if (this.loadingCode || this.loadingPhone) return true
      if (this.code === null) {
        if (this.clearedPhone.length !== 11) return true
        if (this.error.length > 0) return true
      } else if (this.code.length > 0) return false
      return false
    },

    authBtn() {
      if (this.code === null) return 'Запросить код'
      else return 'Войти'
    },

    chatsList() {
      const chats = this.chats
      chats.sort((a, b) => {
        const createdA = a.message ? a.message.created : a.created;
        const createdB = b.message ? b.message.created : b.created;
        return new Date(createdB).getTime() - new Date(createdA).getTime()
      })
      return chats
    },

    chat() {
      return this.chats.filter(it => it.id === this.currentChatId)[0]
    },

  },

  methods: {
    byId(chatId) {
      return this.chats.filter(it => it.id === chatId)[0]
    },
    isClosed(chat) {
      return new Date(chat.created).getTime() !== new Date(chat.deleted).getTime() && new Date().getTime() > new Date(chat.deleted).getTime()
    },
    visibilityChanged(it, e, ee) {
      if (e && it.seen.map(user => user.id).indexOf(this.currentProfile.id) === -1 && this.messagesSeen.indexOf(it.id) === -1) {
        this.messagesSeen.push(it.id)
        console.log('seen')
        console.log(it.seen.map(user => user.id).indexOf(this.currentProfile.id))
        console.log(it.id)
        this.seen(it)
      }
    },

    seen(message) {
      this.stompClient.send("/app/seen", {}, JSON.stringify({
        userId: this.currentProfile.id,
        messageId: message.id
      }))
    },

    photo(users) {
      if (!users) return ''
      const currentUsers = users.filter(it => it.id !== this.currentProfile.id)
      return currentUsers[0].media
    },

    exit() {
      this.setToken(null)
      this.setSalt(null)
      // this.phone = ''
      this.code = null
      this.isAuth = false
      this.loading = false
      this.messages = []
      this.profiles = []
      this.clearError()
    },

    getChatTime(time) {
      const date = new Date(time)
      const currentDate = new Date()
      if (date.getDate() === currentDate.getDate()) return date.toLocaleTimeString()
      if (date.getDate() + 1 === currentDate.getDate()) return 'Вчера'
      return date.toLocaleDateString()
    },

    connect(patientId) {
      if (patientId === -1) return

      const onConnected = () => {
        this.isOffline = false
        this.loadMessages()

        const onMessageReceived = message => {
          const chat = JSON.parse(message.body)
          console.log('connect onMessageReceived')
          console.log(chat)
          if (this.chats.some(it => it.id === chat.id)) {
            const chats = this.chats.filter(it => it.id !== chat.id)
            chats.push(chat)
            this.chats = chats
          } else {
            this.chats.push(chat)
          }
          this.loadMessages()
          this.playSound()
        }

        if (this.currentProfile !== null) {
          if (this.messagesSubscription)
            this.messagesSubscription.unsubscribe()
          this.messagesSubscription = this.stompClient.subscribe(`/patient/${this.currentProfile.uid}`, onMessageReceived)
        }
      }

      const connect = () => {
        const sock = new SockJS("/chats/ws")
        this.stompClient = Stomp.over(sock)
        this.stompClient.heartbeat.outgoing = 1000 * 3
        this.stompClient.heartbeat.incoming = 1000 * 3
        this.stompClient.connect({}, onConnected, onError);
      }

      const onError = err => {
        console.error(err)
        this.isOffline = true
        this.stompClient.disconnect()
        setTimeout(() => connect(), 1000)
      }

      connect()
    },

    async checkToken() {
      const request = async (token, salt, retry) => {
        console.log('checkToken')
        try {
          const res = await axios.get(`/chats/token/${token}/${this.patientId}/${salt}`)
          if (res.status === 200) {
            if (res.data === 'false' || res.data === false) {
              this.exit()
              return
            }
            this.profiles = res.data
            if (this.profiles.length === 1) this.currentProfile = this.profiles[0]
            this.loading = false
            return res.data !== false
          } else if (retry === 0) {
            console.error(res)
            this.exit()
            alert('Ошибка загрузки профилей')
            return false
          }
          await request(token, salt, retry - 1)
        } catch (e) {
          if (retry === 0) {
            console.error(e)
            this.exit()
            alert('Ошибка загрузки профилей')
            return false
          }
          return await request(token, salt, retry - 1)
        }
      }

      this.loading = true
      const token = this.getToken()
      const salt = this.getSalt()
      if (token === null || salt === null || token === 'null' || salt === 'null') {
        this.loading = false
        return false
      }
      return await request(token, salt, 3)
    },

    getToken() {
      return localStorage.getItem('token')
    },

    setToken(token) {
      if (token === null) localStorage.removeItem('token')
      localStorage.setItem('token', token)
    },

    getSalt() {
      return localStorage.getItem('salt')
    },

    setSalt(salt) {
      if (salt === null) localStorage.removeItem('salt')
      localStorage.setItem('salt', salt)
    },

    clearError() {
      this.error = ''
    },

    async auth() {
      let salt = this.getSalt()
      if (!salt || salt === 'null') {
        salt = new Date().getTime()
        this.setSalt(salt)
      }
      if (this.code === null) {
        try {
          this.loadingPhone = true
          const res = await axios.get(`/chats/code/${this.clearedPhone}/${this.patientId}/${salt}`)
          if (res.data === 404) {
            this.phone = ''
            setTimeout(() => {
              this.error = 'Телефон не найден'
            }, 1)
            return
          }
          if (res.data === 429) {
            this.phone = ''
            setTimeout(() => {
              this.error = 'Много попыток, попробуйте позже'
            }, 1)
            return
          }
          if (res.data === 200) {
            this.code = ''
            setTimeout(() =>
            document.getElementById('code').focus(), 200)
          }
          else {
            this.phone = ''
            this.code = null
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.loadingPhone = false
        }
      } else {
        try {
          this.loadingCode = true
          const res = await axios.get(`/chats/code/${this.clearedPhone}/${this.patientId}/${this.code}/${salt}`)
          if (res.data === false) {
            setTimeout(() => {
              this.error = 'Код не подходит'
            }, 1)
            this.code = ''
          } else {
            this.clearError()
            this.code = ''
            this.setToken(res.data)
            this.isAuth = true
            await this.checkToken()
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.loadingCode = false
        }
      }
    },

    playSound() {
      const audio = new Audio(require('@/assets/bb.mp3'))
      audio.play()
    },

    getMessage(it, isDoctor) {
      const regex = /((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[.a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?(\/[\S]{2,})*)/
      return it.replace(regex, `<a style="text-decoration: underline; color: ${ isDoctor ? '#fff' : 'blue' }" href="$1">$1</a>`)
    },

    getMessageDate(it) {
      if (new Date().toLocaleDateString() === new Date(it).toLocaleDateString())
        return moment(new Date(it), 'HH:mm', 'ru').format('HH:mm')
      else return moment(new Date(it), 'HH:mm - D MMM', 'ru').format('HH:mm - D MMM')
    },

    send() {
      document.getElementById('send-btn').blur()
      if (this.message.trim().length === 0) {
        this.message = ''
        return
      }
      if (this.currentProfile === null) {
        alert('Выберите профиль')
        return
      }
      this.stompClient.send("/app/chat", {}, JSON.stringify({
        chat: { id: this.currentChatId },
        user: { id: this.currentProfile.id },
        content: this.message
      }))
      this.message = ''
    },

    selectChat(chat) {
      if (this.currentChatId === chat.id) this.clearChat()
      else {
        this.currentChatId = chat.id
        this.loadMessages()
      }
    },

    clearChat() {
      this.currentChatId = -1
      this.messages = []
    },

    async loadMessages() {
      if (this.currentChatId === -1 || this.isLoadingMessages) return
      const time = new Date().getTime()
      this.isLoadingMessages = true
      const res = await axios.get(`/chats/messages/${this.currentChatId}`)
      const delta = new Date().getTime() - time
      setTimeout(() => {
        this.messagesSeen = []
        this.messages = res.data
        this.isLoadingMessages = false
      }, delta > 150 ? 150 : 300)
      return res.data
    },

    async loadChats() {
      const res = await axios.get(`/chats/patient/${this.currentProfile.id}`)
      this.chats = res.data
    },

    selectProfile(it) {
      if (this.currentProfile !== it)
        this.chats = []
      this.currentProfile = it
    }

  }
}
</script>

<style scoped>

.md-app-content {
  position: relative;
  background: #4bb0fe;
  transition: all 2s;
}

.main-svg {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  /*animation-name: rotatesimp;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 10s;*/
}

html,
body,
.md-app,
.md-app-container,
.md-app-content,
.wrapper {

  height: 100vh !important; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
  height: -webkit-fill-available;
  max-height: 100vh !important; /* Use vh as a fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * 100) !important;
  max-height: -webkit-fill-available;
  overflow: hidden;
}

.wrapper {
  display: flex;
}

.login-wrapper {
  display: flex;
}

.md-app-content {
  overflow: hidden;
  padding: 0 !important;
}

* {
  font-family: "Fira Sans";
}

::-webkit-scrollbar-button {
  background-image: url("");
  background-repeat: no-repeat;
  width: 6px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 4px;
  background-color: #448aff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #448aff;
}

::-webkit-resizer {
  background-image: url("");
  background-repeat: no-repeat;
  width: 4px;
  height: 0px;
}

::-webkit-scrollbar {
  width: 4px;
}

.sidebar {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 400px;
  min-width: 400px;
  overflow: hidden;
  border-right: 1px solid #eee;
  position: relative;
  z-index: 1;
}

.offline {
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 16px;
  background: tomato;
  border-radius: 9px;
  color: #fff;
  padding: 8px 16px;
  text-align: center;
  z-index: 1;
  box-shadow: 5px 0 2px rgb(0 10 30 / 2%), 3px 4px 4px rgb(0 10 30 / 4%), 1px 8px 6px rgb(0 10 30 / 6%);
}

.label {
  display: block;
  text-align: center;
  padding: 12px 0 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #404344;
  font-weight: 400;
  font-size: 12px;
}

.sidebar-search {
  height: 72px;
  padding: 0 16px;
}

.sidebar-content {
  height: 100%;
  overflow-y: auto;
}

.sidebar-item-active {
  background: #4489ff38 !important;
}

.count {
  display: inline-block;
  background: tomato;
  width: 18px;
  border-radius: 50%;
  color: white;
  padding: 2px;
  height: 17px;
}

.md-card {
  box-shadow: 5px 0 2px rgb(0 10 30 / 2%),
  3px 4px 4px rgb(0 10 30 / 4%),
  1px 8px 6px rgb(0 10 30 / 6%);
  border-radius: 8px;
}


.chat-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  z-index: 1;
  font-size: 16px;
}

.login {
  width: 400px;
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  padding: 32px;
  margin: 32px;
  cursor: pointer;
  border-radius: 12px;
  box-shadow: 0 8px 32px #23232377;
  z-index: 1;
}

.login h2 {
  margin-top: 0;
  padding-top: 0;
  text-align: center;
}


.login-error {
  display: block;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: tomato;
  margin-bottom: 16px;
}

.login button {
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  background: #42b983;
  color: #fff;
}

.login button:disabled {
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  background: #9ebbae;
  color: #fff;
}

.chat-preview {
  background: white;
  margin-bottom: 12px;
  padding: 12px 16px;
  cursor: pointer;
}

.profile-freview {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-preview-active {
  background: #f0ffff;
}

.chat-preview:hover {
  background: #e0ffff;
}

.chat-preview-clients {
  font-weight: 500;
  display: flex;
  color: #404344;
  letter-spacing: 0.45px;
  font-size: 15px;
  width: 100%;
}

.chat-preview-content {
  display: flex;
  align-items: center;
}

.chat-preview-text {
  color: #404344;
  font-size: 14px;
  width: 100%;
  margin-right: 16px;
  margin-top: 6px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.chat-preview-date {
  font-size: 12px;
  padding-top: 1px;
  letter-spacing: calc(1px / 4);
  color: #888;
  font-family: monospace;
  width: 15em;
  text-align: right;
}

.rooms-search i {
  width: 48px;
  color: #929aa2;
}

.rooms-search input {
  width: 100%;
  padding: 0;
  height: 32px;
  border: none;
  background: none;
  color: #929aa2;
}

.rooms-search input:focus {
  outline: none;
  color: #000;
}


.message {
}

.message-text {
  font-size: 15px;
  border-radius: 5px;
  color: #555;
  word-break: break-word;
}

.message-date {
  font-size: 10px;
  padding: 2px 4px;
  letter-spacing: 0.25px;
  color: deepskyblue;
  font-family: monospace;
  display: flex;
  align-items: center;
}


.patient-message .message-text {

  border: 1px solid #eee;
}

.patient-message .message-text,
.doctor-message .message-text,
.system-message .message-text {
  margin-top: 12px;
  display: inline-block;
  width: auto;
  background: #f7f7fc;
  padding: 8px 12px;
  border-radius: 11px;
}

.doctor-message .message-date {
  justify-content: right;
}


.patient-message .message-date {
  justify-content: left;
}

.system-message {
  width: 100% !important;
  display: block;
}

.system-message .message-text {
  background: none;
  margin: 16px 0;
  display: block;
  width: 100%;
  max-width: calc(100% - 16px) !important;
  text-align: center;
}

.doctor-message .message-text {
  background: #3182ff;
  color: #fff !important;
}

.doctor-message-a {
  color: #fff !important;
}

.message-text {
  max-width: 40em;
}

.doctor-message {
  margin-left: auto;
  margin-right: 16px;
  text-align: right;
}

.message-link {
  color: #fff !important;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.content {
  width: 100%;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  overflow: auto;
  background: #fbfcfd;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.messages {
  position: relative;
  flex: 1;
  overflow: auto;
  border-bottom: 1px solid #eee;
  background: #fff;
  padding-left: 16px;
}

.editor-wrapper {
  background: #eefcfc;
  padding-top: 12px;
}

.editor {
  height: 108px;
  position: relative;
  border-radius: 12px;
  margin: 0 12px 12px;
}

.editor hr {
  height: 1px;
  background: #ccc;
  border: none;
}

.editor span {
  margin-right: 12px;
  border-radius: 6px;
  background: #eee;
  padding: 2px 8px;
  line-height: 22px;
  font-size: 12px;
  margin-bottom: 4px;
}

.editor textarea {
  background: none;
  border: none;
  height: calc(96px - 12px - 12px - 24px);
  width: calc(100%);
  outline: none;
  font-size: 15px;
}

.send-btn {
  bottom: 24px;
  right: 24px;
  padding: 8px 24px;
  margin: 4px;
  float: right;
  border: none;
  color: darkgreen;
  background: lightgreen;
  border-radius: 8px;
}

.send-btn:hover {
  cursor: pointer;
  color: #000;
  border-color: #555;
  background: #eee;
}

.hidden {

}

.content-header {
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-bottom: 1px solid #eee;
  background: #cefcfc
}

.content-header .back {
  border: none;
  color: #404344;
  background: none;
  display: none;
}

.editor-hint {
  color: #999;
  padding-top: 12px;
  padding-left: 4px;
  display: inline-block
}

@media (max-width: 1025px) {
  .sidebar {
    width: 100%;
    min-width: 100%;
    border-right: none;
  }

  .message-text {
    max-width: 20em;
  }

  .editor-hint {
    display: none;
  }

  .hidden {
    display: none;
  }

  .messages {
    height: calc(100vh - 133px - 56px);
    background-repeat: repeat;
  }

  .content-header {
    color: #404344;
  }

  .content-header .back {
    border: none;
    color: #404344;
    background: none;
    display: block;
    padding-right: 8px;
  }
}

.sidebar-header {
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.sidebar-header * {
  z-index: 2;
}

.sidebar-header .label {
  z-index: 2;
}

.sidebar-header:before,
.sidebar-header:after {
  content: "";
  position: absolute;
  left: 50%;
  min-width: 900vw;
  min-height: 900vw;
  background-color: #FCFFF5;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  z-index: 0;
}

.sidebar-header:before {
  bottom: 1vh;
  border-radius: 50.5%;
  animation-duration: 10s;
}

.sidebar-header:after {
  bottom: 0vh;
  opacity: .5;
  border-radius: 49.5%;
  animation-duration: 10s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    transform: translate(-50%, -0.25%) rotateZ(180deg);
  }
  100% {
    transform: translate(-50%, 0%) rotateZ(360deg);
  }
}

@keyframes rotatesimp {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(180deg);
  }
}


.loader {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #0077b6 #0077b6 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #90e0ef #90e0ef;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader::before {
  width: 20px;
  height: 20px;
  border-color: #00b4d8 #00b4d8 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.bg1 {
  position: absolute;
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 0;
  top: 0;
  height: 1000px;
  width: 1000px !important;
  animation: 45s linear 1s infinite rotation;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}

.messages-bg {
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
  bottom: 0;
    width: 100%;
  z-index: 1;
  background-repeat: repeat;
}

.photo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid lightblue;
  margin-right: 16px;
}

.chat-preview-closed {
  background: rgba(255, 99, 71, 0.7);
}
</style>
